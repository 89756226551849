import React from "react";
import theme from "theme";
import { Theme, Text, Box, Section, Button, Image } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"index"} />
		<Helmet>
			<title>
				Autobahn Legends Club
			</title>
			<meta name={"description"} content={"Совершенство на колесах – почувствуйте гордость немецкой техники"} />
			<meta property={"og:title"} content={"Autobahn Legends Club"} />
			<meta property={"og:description"} content={"Совершенство на колесах – почувствуйте гордость немецкой техники"} />
			<meta property={"og:image"} content={"https://uploads.quarkly.io/6659a0828d4a0c00202fd67d/images/1-2.jpg?v=2024-05-31T12:02:30.799Z"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/6659a0828d4a0c00202fd67d/images/1-2.jpg?v=2024-05-31T12:02:30.799Z"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://uploads.quarkly.io/6659a0828d4a0c00202fd67d/images/1-2.jpg?v=2024-05-31T12:02:30.799Z"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://uploads.quarkly.io/6659a0828d4a0c00202fd67d/images/1-2.jpg?v=2024-05-31T12:02:30.799Z"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://uploads.quarkly.io/6659a0828d4a0c00202fd67d/images/1-2.jpg?v=2024-05-31T12:02:30.799Z"} />
			<link rel={"apple-touch-startup-image"} href={"https://uploads.quarkly.io/6659a0828d4a0c00202fd67d/images/1-2.jpg?v=2024-05-31T12:02:30.799Z"} />
			<meta name={"msapplication-TileImage"} content={"https://uploads.quarkly.io/6659a0828d4a0c00202fd67d/images/1-2.jpg?v=2024-05-31T12:02:30.799Z"} />
		</Helmet>
		<Components.Header>
			<Override slot="text">
				Autobahn Legends Club
			</Override>
		</Components.Header>
		<Section background="--color-darkL2" padding="60px 0" sm-padding="40px 0">
			<Box
				margin="-16px -16px -16px -16px"
				padding="0px 0px 0px 0px"
				display="flex"
				width=" "
				flex-wrap="wrap"
			>
				<Box padding="16px 16px 16px 16px" display="flex" width="50%" lg-width="100%">
					<Box
						padding="98px 64px 98px 64px"
						display="flex"
						flex-direction="column"
						background="--color-light"
						color="--dark"
						align-self="center"
					>
						<Text
							as="h4"
							margin="12px 0"
							font="--base"
							color="--grey"
							letter-spacing="1px"
							text-transform="uppercase"
						>
							МИР, ГДЕ ТОЧНОСТЬ СОЕДИНЯЕТСЯ С Роскошью.
						</Text>
						<Text as="h2" margin="12px 0" font="--headline3" md-font="--headline3">
							В клубе Autobahn Legends Club мы празднуем вершину немецкого автомобильного мастерства, объединяя энтузиастов Audi, BMW и Mercedes. Наш клуб – ворота, чтобы почувствовать культовые характеристики и наследие лучших автомобилей Германии в сообществе страстных поклонников.
						</Text>
					</Box>
				</Box>
				<Box display="flex" width="50%" flex-wrap="wrap" lg-width="100%">
					<Box
						padding="16px 16px 16px 16px"
						display="flex"
						width="100%"
						lg-width="33.3333%"
						md-width="100%"
						sm-width="100%"
					>
						<Box
							padding="0px 0px 624px 0px"
							display="flex"
							flex-direction="column"
							background="url(https://uploads.quarkly.io/6659a0828d4a0c00202fd67d/images/2-2.jpg?v=2024-05-31T12:02:30.764Z) top/cover"
							width="100%"
							lg-padding="0px 0px 248px 0px"
							sm-padding="0px 0px 380px 0px"
						/>
					</Box>
					<Box
						padding="16px 16px 16px 16px"
						display="flex"
						width="50%"
						lg-width="33.3333%"
						md-width="50%"
						sm-width="100%"
					>
						<Box
							padding="0px 0px 296px 0px"
							display="flex"
							flex-direction="column"
							background="url(https://uploads.quarkly.io/6659a0828d4a0c00202fd67d/images/1-4%20%281%29.jpg?v=2024-05-31T12:02:30.760Z) center/cover"
							width="100%"
							lg-padding="0px 0px 248px 0px"
							sm-padding="0px 0px 380px 0px"
						/>
					</Box>
					<Box
						padding="16px 16px 16px 16px"
						display="flex"
						width="50%"
						lg-width="33.3333%"
						md-width="50%"
						sm-width="100%"
					>
						<Box
							padding="0px 0px 296px 0px"
							display="flex"
							flex-direction="column"
							background="url(https://uploads.quarkly.io/6659a0828d4a0c00202fd67d/images/2-3.jpg?v=2024-05-31T12:02:30.743Z) center/cover"
							width="100%"
							lg-padding="0px 0px 248px 0px"
							sm-padding="0px 0px 380px 0px"
						/>
					</Box>
				</Box>
			</Box>
		</Section>
		<Section padding="90px 0 100px 0" quarkly-title="List-5">
			<Override slot="SectionContent" max-width="100%" />
			<Text margin="0px 0px 90px 0px" text-align="center" font="normal 600 42px/1.2 --fontFamily-sans" md-margin="0px 0px 60px 0px">
				О нашем клубе
			</Text>
			<Box
				min-width="100px"
				min-height="100px"
				display="grid"
				grid-template-columns="repeat(2, 1fr)"
				grid-gap="10px"
				lg-grid-template-columns="repeat(2, 1fr)"
				sm-grid-template-columns="1fr"
				sm-grid-gap="36px 0"
				md-grid-gap="50px 0"
				margin="0px 30px 0px 30px"
				lg-margin="0px 0 0px 0"
				md-grid-template-columns="1fr"
				width="100%"
			>
				<Box
					min-width="10px"
					min-height="10px"
					display="flex"
					flex-direction="column"
					padding="0px 50px 0px 0px"
					align-items="flex-start"
					md-padding="0px 0 0px 0px"
					align-self="center"
					justify-self="center"
				>
					<Text margin="0px 0px 35px 0px" font="normal 300 18px/1.5 --fontFamily-sansHelvetica" color="#474a4d" md-margin="0px 0px 25px 0px">
						Autobahn Legends Club – это больше, чем просто автомобильный клуб, это культура. Основываясь на глубокой благодарности за инженерное совершенство и богатое автомобильное наследие, мы предлагаем членам эксклюзивный доступ к событиям, дорожным поездкам и демонстрациям, подчеркивающим инновации и мастерство немецких автопроизводителей. От классических моделей до последних инноваций наши мероприятия охватывают весь спектр того, что могут предложить немецкие автомобили.
					</Text>
					<Button
						background="#0438cd"
						padding="11px 28px 11px 28px"
						border-radius="25px"
						type="link"
						href="/services"
					>
						УЗНАТЬ БОЛЬШЕ
					</Button>
				</Box>
				<Image
					src="https://uploads.quarkly.io/6659a0828d4a0c00202fd67d/images/2-6.jpg?v=2024-05-31T12:02:30.755Z"
					display="block"
					width="100%"
					md-order="-1"
					srcSet="https://smartuploads.quarkly.io/6659a0828d4a0c00202fd67d/images/2-6.jpg?v=2024-05-31T12%3A02%3A30.755Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/6659a0828d4a0c00202fd67d/images/2-6.jpg?v=2024-05-31T12%3A02%3A30.755Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/6659a0828d4a0c00202fd67d/images/2-6.jpg?v=2024-05-31T12%3A02%3A30.755Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/6659a0828d4a0c00202fd67d/images/2-6.jpg?v=2024-05-31T12%3A02%3A30.755Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/6659a0828d4a0c00202fd67d/images/2-6.jpg?v=2024-05-31T12%3A02%3A30.755Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/6659a0828d4a0c00202fd67d/images/2-6.jpg?v=2024-05-31T12%3A02%3A30.755Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/6659a0828d4a0c00202fd67d/images/2-6.jpg?v=2024-05-31T12%3A02%3A30.755Z&quality=85&w=3200 3200w"
					sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
				/>
				<Image
					src="https://uploads.quarkly.io/6659a0828d4a0c00202fd67d/images/2-4.jpg?v=2024-05-31T12:02:30.742Z"
					display="block"
					width="100%"
					srcSet="https://smartuploads.quarkly.io/6659a0828d4a0c00202fd67d/images/2-4.jpg?v=2024-05-31T12%3A02%3A30.742Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/6659a0828d4a0c00202fd67d/images/2-4.jpg?v=2024-05-31T12%3A02%3A30.742Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/6659a0828d4a0c00202fd67d/images/2-4.jpg?v=2024-05-31T12%3A02%3A30.742Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/6659a0828d4a0c00202fd67d/images/2-4.jpg?v=2024-05-31T12%3A02%3A30.742Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/6659a0828d4a0c00202fd67d/images/2-4.jpg?v=2024-05-31T12%3A02%3A30.742Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/6659a0828d4a0c00202fd67d/images/2-4.jpg?v=2024-05-31T12%3A02%3A30.742Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/6659a0828d4a0c00202fd67d/images/2-4.jpg?v=2024-05-31T12%3A02%3A30.742Z&quality=85&w=3200 3200w"
					sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
				/>
				<Box
					min-width="10px"
					min-height="10px"
					display="flex"
					flex-direction="column"
					padding="0px 30px 0px 20px"
					align-items="flex-start"
					md-padding="0px 0 0px 0"
					align-self="center"
				>
					<Text margin="0px 0px 25px 0px" font="normal 500 28px/1.2 --fontFamily-sans" md-margin="0px 0px 20px 0px">
						Почему выбирают нас?
					</Text>
					<Text margin="0px 0px 35px 0px" font="normal 300 18px/1.5 --fontFamily-sansHelvetica" color="#474a4d" md-margin="0px 0px 25px 0px">
						Оценка наследия: Погрузитесь в историю и эволюцию ведущих автомобильных брендов Германии.
						<br />
						<br />
						{"\n\n"}Эксклюзивные события: получайте эксклюзивный доступ к частным выставкам, встречам только для членов и больших гастролей.{"\n"}
						<br />
						<br />
						{"\n"}Сетевые возможности: Общайтесь с коллегами-любителями автомобилей, которые разделяют ваше пристрастие к высококачественной немецкой технике.
					</Text>
				</Box>
			</Box>
		</Section>
		<Components.Contacts>
			<Override slot="text1">
				Присоединяйтесь к нам и станьте частью наследия, которое чествует не просто автомобили, это праздник стиля жизни. Общайтесь с лучшими, управляйте лучшими, будьте лучшими.
			</Override>
			<Override slot="text">
				Звоните нам!
			</Override>
			<Override slot="link" />
			<Override slot="link1" href="helllo@spectrixfill.com">
				helllo@spectrixfill.com
			</Override>
			<Override slot="text3">
				Почта
			</Override>
			<Override slot="text2" />
		</Components.Contacts>
		<Components.Footer>
			<Override slot="link" />
			<Override slot="text" />
			<Override slot="link1" />
			<Override slot="link2" />
			<Override slot="link3" />
			<Override slot="icon1" />
		</Components.Footer>
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65bb71f98e2e8e0021771970"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});